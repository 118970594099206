import React from "react"
import CategoryView from "../../views/category-view"
import { graphql } from 'gatsby'

const ForschungCategory = (props) => {
  const projects = props.data.forschung.nodes
  const imgData = props.data.images.nodes
  const location = props.location
  const category = props.data.category
  const categories = props.data.categories.nodes

  return <CategoryView 
    data={props.data}
    projects={projects} 
    location={location}
    images={imgData} 
    category={category}
    categories={categories} />
}

export default ForschungCategory


export const query = graphql`
  query {
    forschung: allForschung{
      nodes {
        title
        slug
        category
        images {
          alt
          alt_over
        }
      }
    }
    images: allFile(filter: {name: {regex: "/^([0-9 a-z A-Z]*)(_*)([^0-9]*)$/" }, 
    extension: {regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)|(avif)/"}}) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            formats: [AUTO, WEBP, AVIF]
            outputPixelDensities: [0.25, 0.5, 0.75, 1, 2]
            width: 300
          )
        }
        relativePath
      }
    }
    category: categories(_id: {eq: 10}) {
      _id
      slug
      name
      description
      type
      meta
      body
      files { 
        url
        text1
        text2
      }
    }
    categories: allCategories {
      nodes {
        _id
        slug
        name
        description
        type
        meta
        body
        files {
          url
          text1
          text2
        }
      }
    }
  }
`